import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { MenuItem, Stack } from '@mui/material';
import { useState } from 'react';
import { IconButtonAnimate } from '../../../components/animate';
import Image from '../../../components/image';
import MenuPopover from '../../../components/menu-popover';
import { useLocales } from '../../../locales';
// ----------------------------------------------------------------------
export default function LanguagePopover() {
    const { allLangs, currentLang, onChangeLang } = useLocales();
    const [openPopover, setOpenPopover] = useState(null);
    const handleOpenPopover = (event) => {
        setOpenPopover(event.currentTarget);
    };
    const handleClosePopover = () => {
        setOpenPopover(null);
    };
    const handleChangeLang = (newLang) => {
        onChangeLang(newLang);
        handleClosePopover();
    };
    return (_jsxs(_Fragment, { children: [_jsx(IconButtonAnimate, { onClick: handleOpenPopover, sx: {
                    width: 40,
                    height: 40,
                    ...(openPopover && {
                        bgcolor: 'action.selected',
                    }),
                }, children: _jsx(Image, { disabledEffect: true, src: currentLang.icon, alt: currentLang.label }) }), _jsx(MenuPopover, { open: openPopover, onClose: handleClosePopover, sx: { width: 180 }, children: _jsx(Stack, { spacing: 0.75, children: allLangs.map((option) => (_jsxs(MenuItem, { selected: option.value === currentLang.value, onClick: () => handleChangeLang(option.value), children: [_jsx(Image, { disabledEffect: true, alt: option.label, src: option.icon, sx: { width: 28, mr: 2 } }), option.label] }, option.value))) }) })] }));
}
